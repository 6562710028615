import moment from "moment";
import React, { FC, useState } from "react";
import XLSX from "xlsx";
import {
  TableFilterInputText,
  TableFilterRangeTimeInput,
} from "../../components/table-filter-inputs";
import { getLocaleKey, translate } from "../../languages";
import { Button, NumberUtils, Table } from "../../modules";
import { AdminService } from "../../services/admin";

export const PageGameOrdersList: FC = (props: any) => {
  const [currentData, setCurrentData] = useState(null as any);
  // const [isShowTable, setIsShowTable] = useState<boolean>(true);
  const [params, setParams] = useState<any>();

  const startOfMonth = moment()
    .subtract(1, "months")
    .startOf("month")
    .format("YYYY/MM/DD HH:mm:ss");
  const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

  return (
    <div className="page-game-orders-list">
      <div className="button-container">
        <Button
          disabled={!currentData}
          label="Export to Excel"
          buttonType="success"
          className="mb15"
          onClick={async () => {
            // const response = await AdminService.getListRequestClaim({
            //     numberOfTransactionsPerPage: 10000,
            //     page: 1,
            //     fromDate: startOfMonth, //'0',
            //     toDate: endOfDay //moment().toISOString()
            // });
            const response = await AdminService.getListGameOrders({
              ...params,
              page: 1,
              pageSize: 100000,
            });
            const data = [
              ["Supplier", "Created Date", "UID", "Email", "Game", "Amount", "Result"],
              ...response.data.map((item: any) => {
                let row: any[] = [];
                row.push(item?.supplier ?? "-");
                row.push(new Date(item?.created).toLocaleString(getLocaleKey()) ?? "-");
                row.push(item?.thirdPartyUid ?? "-");
                row.push(item?.email ?? "-");
                row.push(item?.gameType ?? "-");
                row.push(NumberUtils.toFormatNumber(item?.amount) ?? "-");
                row.push(
                  item.status === "LOSE"
                    ? item.profit > 0
                      ? NumberUtils.toFormatNumber(item.amount - item.profit, 8)
                      : NumberUtils.toFormatNumber(item.amount, 8)
                    : item.status === "WIN"
                    ? NumberUtils.toFormatNumber(item.profit - item.amount, 8)
                    : NumberUtils.toFormatNumber(item.profit, 8) ?? "-"
                );
                return row;
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `Game-Orders-List-${now.toLocaleDateString().replace(/\//g, "-")} ${now
                .toLocaleTimeString()
                .replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );
          }}
        />
      </div>

      {
        // isShowTable
        true && (
          <Table
            hasOrderColumn
            className="overload"
            filters={[
              {
                name: "Supplier",
                key: "supplier",
                input: TableFilterInputText,
              },
              {
                name: "Email",
                key: "searchString",
                input: TableFilterInputText,
              },
              {
                name: "Game",
                key: "gameType",
                input: TableFilterInputText,
              },
              {
                name: translate("time"),
                key: "created",
                input: (e) => (
                  <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />
                ),
                defaultValue: {
                  fromDate: startOfMonth,
                  toDate: endOfDay,
                },
              },
            ]}
            structure={[
              {
                name: "Supplier",
                render: (item) => item?.supplier ?? "-",
              },
              {
                name: "Created Date",
                render: (item) => moment(item?.created).format("L LT") ?? "-",
              },
              {
                name: "UID",
                render: (item) => item?.thirdPartyUid ?? "-",
              },
              {
                name: "Email",
                render: (item) => item?.email ?? "-",
                className: "email",
              },
              {
                name: "Game",
                render: (item) => item?.gameType ?? "-",
              },
              {
                name: "Amount",
                render: (item) => NumberUtils.toFormatNumber(item?.amount, 8) ?? "-",
              },
              {
                name: "Result",
                render: (item: any) => (
                  <span className={`status status--${item?.status?.toLowerCase()}`}>
                    {item.status === "LOSE"
                      ? item.profit > 0
                        ? NumberUtils.toFormatNumber(item.amount - item.profit, 8)
                        : NumberUtils.toFormatNumber(item.amount, 8)
                      : item.status === "WIN"
                      ? NumberUtils.toFormatNumber(item.profit - item.amount, 8)
                      : NumberUtils.toFormatNumber(item.profit, 8)}
                  </span>
                ),
              },
            ]}
            fetchData={async (state) => {
              let params = { ...state };
              setParams(params);
              if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
              if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

              return AdminService.getListGameOrders({
                page: params.pageNumber,
                pageSize: params.limit,
                ...params,
              }).then((res) => {
                setCurrentData(res);
                return res;
              });
            }}
          />
        )
      }
    </div>
  );
};
