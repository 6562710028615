import moment from "moment";
import React, { useState } from "react";
import XLSX from "xlsx";
import {
  TableFilterInputText,
  TableFilterRangeTimeInput,
} from "../../../components/table-filter-inputs";
import { getLocaleKey, translate } from "../../../languages";
import { Button, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withFranchiseWraper } from "../wraper";

export const PageFranchiseLevelSettingHistory = withFranchiseWraper(() => {
  const [currentData, setCurrentData] = useState(null as any);
  const startOfMonth = moment()
    .subtract(1, "months")
    .startOf("month")
    .format("YYYY/MM/DD HH:mm:ss");
  const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

  return (
    <div className="PageFranchiseLevelSettingHistory">
      <Button
        disabled={!currentData}
        label="Export to Excel"
        buttonType="success"
        className="mb15"
        onClick={async () => {
          // const response = await AdminService.getListLevelSettingHistory({
          //     numberOfTransactionsPerPage: 10000,
          //     page: 1,
          //     fromDate: '0',
          //     toDate: moment().toISOString()
          // });
          const data = [
            ["Time", "Created By", "User Applied", "From Level", "To Level"],
            ...currentData.data.map((item: any) => {
              let row: any[] = [];
              row.push(new Date(item?.created).toLocaleString(getLocaleKey()));
              row.push(item.createdEmail);
              row.push(item.email);
              row.push(item.rankBefore);
              row.push(item.rank);
              return row;
            }),
          ];

          const ws = XLSX.utils.aoa_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(
            wb,
            `Level Setting History ${now.toLocaleDateString().replace(/\//g, "-")} ${now
              .toLocaleTimeString()
              .replace(/:/g, "-")}.xlsx`,
            { type: "binary" }
          );
        }}
      />

      <Table
        hasOrderColumn
        className="overload"
        filters={[
          {
            name: "User Applied",
            key: "email",
            input: TableFilterInputText,
          },
          {
            name: "Time",
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
            defaultValue: {
              fromDate: startOfMonth,
              toDate: endOfDay,
            },
          },
        ]}
        structure={[
          {
            name: "TIME",
            key: "created",
            render: (item) => {
              const date = new Date(item.created);
              return (
                <span>
                  {date.toLocaleDateString(getLocaleKey())}{" "}
                  {date.toLocaleTimeString(getLocaleKey())}
                </span>
              );
            },
          },
          {
            name: "CREATED BY",
            key: "createdEmail",
            className: "created-by",
          },
          {
            name: "USER APPLIED",
            key: "email",
            className: "user-applied",
          },
          {
            name: "FROM LEVEL",
            render: (item) => (
              <img
                className="level"
                src={`/assets/images/rank/rank_${item?.rankBefore}.png`}
                alt=""
              />
            ),
          },
          {
            name: "TO LEVEL",
            render: (item) => (
              <img className="level" src={`/assets/images/rank/rank_${item?.rank}.png`} alt="" />
            ),
          },
        ]}
        // fetchData={async (params) =>
        //     AdminService.getListLevelSettingHistory({
        //         ...params,
        //         numberOfTransactionsPerPage: params.limit,
        //         page: params.pageNumber,
        //     }).then((res) => {
        //         setCurrentData(res);
        //         return res;
        //     })
        // }
        fetchData={async (state) => {
          let params = { ...state };
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

          return AdminService.getListLevelSettingHistory({
            page: params.pageNumber,
            numberOfTransactionsPerPage: params.limit,
            ...params,
          }).then((res) => {
            setCurrentData(res);
            return res;
          });
        }}
      />
    </div>
  );
});
