import moment from "moment";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import XLSX from "xlsx";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
//import { withUserWraper } from "../wraper";
import {
  TableFilterInputText,
  TableFilterRangeTimeInput,
} from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { InputToggleSwitch } from "../../../components/toggle-switch";
import { translate } from "../../../languages";
import { Button, CreateAlert, Icon, ITableStructureItem, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { GameManagementLayout } from "../components/game-management-layout";
import "./index.scss";

export const SupplierListPage: FC<any> = GameManagementLayout(() => {
  const history = useHistory();
  // const [params, setParams] = useState([] as any[]);
  const [currentData, setCurrentData] = useState(null as any);
  // const [forceUpdateTable, setForceUpdateTable] = useState<boolean>();
  const [isShowPopupSwap, setIsShowPopupSwap] = useState<boolean>(false);
  const [dataItemSwap, setDataItemSwap] = useState<any>();
  const [isShowPopupActive, setIsShowPopupActive] = useState<boolean>(false);
  const [dataItemActive, setDataItemActive] = useState<any>();
  //const user = useSelector((state) => state.user);
  // const startOfMonth = moment().subtract(1, "months").startOf("month");
  // const endOfDay = moment().endOf("day");
  const structure: ITableStructureItem[] = [
    {
      name: "SUPPLIER",
      key: "name",
      render: (item) => (
        <div className={`supplier ${!item?.isActive && "inactive"}`}>
          <img
            className="supplier__image"
            src={item?.image ?? "/assets/images/no-image.jpg"}
            alt=""
            onClick={() => {
              if (item?.isActive === false) return;
              window.open(item?.thumnail, "_blank");
            }}
          />
          <div className="supplier__name">{item?.name ?? "unknown"}</div>
        </div>
      ),
    },
    {
      name: "CODE",
      key: "code",
      render: (item) => (
        <div className={`supplier-code ${!item?.isActive && "inactive"}`}>
          {item?.code ?? "unknown"}
        </div>
      ),
    },
    {
      name: "CREATED DATE",
      render: (item) => (
        <div className={`created-date ${!item?.isActive && "inactive"}`}>
          {moment(item?.created).format('DD/MM/y HH:mm:ss')}
        </div>
      ),
    },
    {
      name: "ACTIONS",
      render: (item) => (
        <div className={`action ${!item?.isActive && "inactive"}`}>
          <div className="action--up" onClick={() => handleOnClickAction(item, "UP")}>
            <Icon.SwapUpIcon />
          </div>
          <div className="action--down" onClick={() => handleOnClickAction(item, "DOWN")}>
            <Icon.SwapDownIcon />
          </div>
        </div>
      ),
    },
    {
      name: "EDIT",
      render: (item) => (
        <div
          className={`edit`}
          onClick={() => history.push(`/game-management-supplier/edit/${item?.supplierId}`)}
        >
          <Icon.ActionEditIcon />
        </div>
      ),
    },
    {
      name: "ACTIVE",
      render: (item) => (
        <div className="active">
          <InputToggleSwitch
            onChange={async (value) => {
              handleOnClickSwitchActive(item, value);
            }}
            value={item?.isActive}
            onTouched={() => false}
            label=""
            name=""
          />
        </div>
      ),
    },
  ];

  const handleOnClickAction = (item: any, type: any) => {
    if (item?.isActive === false) return;
    setDataItemSwap({ item, type });
    setIsShowPopupSwap(true);
  };

  const handleOnClickConfirmSwap = () => {
    AdminService.swapSupplier(dataItemSwap?.item?.supplierId, dataItemSwap?.type)
      .then((res) => {
        // setForceUpdateTable((state) => !state);
        CreateAlert({ message: "Swap supplier successfully.", type: "success" });
      })
      .catch((err) => {
        CreateAlert({ message: err?.message, type: "danger" });
      })
      .finally(() => {
        setIsShowPopupSwap(false);
      });
  };

  const handleOnClickSwitchActive = (item: any, value: any) => {
    setDataItemActive({ item, value });
    setIsShowPopupActive(true);
  };

  const handleOnClickConfirmActive = () => {
    AdminService.activeSupplier(dataItemActive?.item?.supplierId, dataItemActive?.value)
      .then((res) => {
        // setForceUpdateTable((state) => !state);
        CreateAlert({ message: `successfully.`, type: "success" });
      })
      .catch((err) => {
        CreateAlert({ message: err?.message, type: "danger" });
      })
      .finally(() => {
        setIsShowPopupActive(false);
      });
  };

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        // const response = await AdminService.getListSupplier({
        //     ...params,
        //     page: 1,
        //     numberOfItemsPerPage: 10000,
        // });

        const data = currentData.data; // response.data;

        const fileHead = structure.map((v) => v.name);
        const dataExport = [
          fileHead,
          ...data.map((item: any) =>
            structure.map((column, index) => {
              if (!column.key) return "";
              return item[column.key];
            })
          ),
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(
          wb,
          `Supplier List ${now.toLocaleDateString().replace(/\//g, "-")} ${now
            .toLocaleTimeString()
            .replace(/:/g, "-")}.xlsx`,
          { type: "binary" }
        );

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error) {
        resolve(CreateAlert({ type: "danger", message: (error as any).message }));
      }
    });
  };

  const statusFilterOptions = [
    { label: "All", value: "ALL" },
    { label: "Active", value: true },
    { label: "Deactive", value: false },
  ];

  return (
    <div className="SupplierPage">
      <div className="button-container">
        <Button
          disabled={!currentData}
          className="mb20"
          label="Export to Excel"
          buttonType="success"
          onClick={handleExportExcel}
        />

        <div className="button-group">
          <Button
            label="Add Supplier"
            buttonType="success"
            icon={Icon.AddNewIcon}
            onClick={() => history.push("/game-management-supplier/create")}
          />
        </div>
      </div>

      <Table
        hasOrderColumn
        filters={[
          {
            name: "Search",
            key: "searchString",
            input: TableFilterInputText,
          },
          {
            name: translate("time"),
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
            // defaultValue: {
            //   //fromDate: moment().subtract(1,"months"),
            //   //toDate: moment(),
            //   fromDate: startOfMonth,
            //   toDate: endOfDay,
            // },
          },
          {
            name: translate("status"),
            key: "isActive",
            input: (s) => (
              <TableFilterInputSelect {...s} options={statusFilterOptions} isClearable={true} />
            ),
          },
        ]}
        structure={structure}
        fetchData={async (params) => {
          // setParams(params);
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
          return AdminService.getListSupplier({
            page: params.pageNumber,
            numberOfItemsPerPage: params.limit,
            ...params,
          }).then((res) => {
            setCurrentData(res);
            // console.log("🚀 ~ file: index.tsx ~ line 260 ~ fetchData={ ~ res", res);
            return res;
          });
        }}
        // forceUpdateTable={forceUpdateTable}
      />

      {isShowPopupSwap ? (
        <GeneralPopupCompnt
          onClose={() => {
            setIsShowPopupSwap(false);
          }}
          onClickConfirm={() => handleOnClickConfirmSwap()}
          textButton="OK"
          titlePopup={"Notification!"}
          messagePopup={"Are you sure you want to swap?"}
        />
      ) : null}

      {isShowPopupActive ? (
        <GeneralPopupCompnt
          onClose={() => {
            setIsShowPopupActive(false);
          }}
          onClickConfirm={() => handleOnClickConfirmActive()}
          textButton="OK"
          titlePopup={"Notification!"}
          messagePopup={`Are you sure you want to ${
            dataItemActive?.value === true ? "active" : "deactivate"
          }?`}
        />
      ) : null}
    </div>
  );
});
