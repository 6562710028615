import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { InputSelect, InputText } from "../../../../components";
import { InputImageSingle } from "../../../../components/single-image";
import { InputToggleSwitch } from "../../../../components/toggle-switch";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import { AdminService } from "../../../../services/admin";
import { GameManagementLayout } from "../../components/game-management-layout";

export const GameCreatePage: FC<any> = GameManagementLayout(() => {
  const history = useHistory();
  const [categoryOptions, setCategoryOptions] = useState<any>();
  const [categoryValue, setCategoryValue] = useState<any>();
  const [supplierOptions, setSupplierOptions] = useState<any>();
  const [supplierValue, setSupplierValue] = useState<any>();
  const [isGameActive, setIsGameActive] = useState<boolean>(true);

  useEffect(() => {
    AdminService.getCategoryList().then((res: any) => {
      setCategoryOptions(
        res?.result?.map((item: any) => ({ label: item?.name, value: item?.categoryId }))
      );
    });
    AdminService.getSupplierList().then((res: any) => {
      setSupplierOptions(
        res?.result?.map((item: any) => ({ label: item?.name, value: item?.supplierId }))
      );
    });
  }, []);

  const { getInputProps, handleSubmit } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "gameImage",
        validate: Yup.string().required("Must be provided"),
      },
      // {
      //   name: "gameThumbnail",
      //   validate: Yup.string().required("Must be provided"),
      // },
      {
        name: "tableId",
        validate: Yup.string().required("Must be provided"),
      },
      {
        name: "tableName",
        validate: Yup.string().required("Must be provided"),
      },
      {
        name: "platform",
        validate: Yup.string().required("Must be provided"),
      },
    ],
    onSubmit: async (values: any) => {
      if (!categoryValue) return CreateAlert({ message: "Category required", type: "danger" });
      if (!supplierValue) return CreateAlert({ message: "Supplier required", type: "danger" });
      let payload = {
        categoryId: +categoryValue,
        supplierId: +supplierValue,
        image: values?.gameImage?.src,
        thumnail: values?.gameImage?.thumbnail,
        isActive: isGameActive,
        tableId: values?.tableId,
        tableName: values?.tableName,
        platform: values?.platform,
      };

      AdminService.CreateGame(payload)
        .then((res: any) => {
          CreateAlert({ message: "Create game successfully.", type: "success" });
        })
        .catch((err: any) => {
          CreateAlert({ message: err?.message, type: "danger" });
        })
        .finally(() => {
          history.push("/game-management-game");
        });
    },
  });

  return (
    <>
      {
        <div className="game-create-page container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="game-create-page__content">
                <div className="title">Create Game</div>
                <div className="form">
                  <div className="row">
                    <div className="col-6 input-container">
                      <div className="label">Category</div>
                      <InputSelect
                        options={categoryOptions}
                        onChange={(e) => setCategoryValue(e)}
                        onTouched={() => false}
                        value={categoryValue}
                        name=""
                      />
                      {/* <InputWraper
                                        enableReinitialize
                                        // className={"image"}
                                        label={"Category"}
                                        inputProps={getInputProps("gameCategory")}
                                        renderInput={(props) => <InputSelect
                                            {...props}
                                            options={categoryOptions}
                                            onChange={(e) => setCategoryValue(e)}
                                            onTouched={() => false}
                                            value={categoryValue}
                                            name=""
                                        />}
                                        // renderInput={(props) => (
                                        //     <InputSelectAsyncData
                                        //         {...props}
                                        //         isClearable={false}
                                        //         isSearchable={false}
                                        //         styleRotateArrow={true}
                                        //         asyncData={{
                                        //             response: supplierOptions,
                                        //             pathLabel: 'label',
                                        //             pathValue: 'value'
                                        //         }}
                                        //     />
                                        // )}
                                    /> */}
                    </div>
                    <div className="col-6 input-container">
                      <div className="label">Supplier</div>
                      <InputSelect
                        options={supplierOptions}
                        onChange={(e) => setSupplierValue(e)}
                        onTouched={() => false}
                        value={supplierValue}
                        name=""
                      />
                    </div>
                    <div className="col-6 input-container">
                      <InputWraper
                        // className={"image"}
                        label={"Image"}
                        inputProps={getInputProps("gameImage")}
                        component={InputImageSingle}
                        // onChangeEvent={(value: any) => {}}
                      />
                    </div>
                    {/* <div className="col-6 input-container">
                      <InputWraper
                        // className={"image"}
                        label={"Thumbnail"}
                        inputProps={getInputProps("gameThumbnail")}
                        component={InputImageSingle}
                        // onChangeEvent={(value: any) => {}}
                      />
                    </div> */}
                    <div className="col-12 input-container">
                      <div className="label">Active</div>
                      <InputToggleSwitch
                        onChange={async (value) => setIsGameActive(value)}
                        value={isGameActive}
                        onTouched={() => false}
                        label=""
                        name=""
                      />
                    </div>
                    <div className="col-4 input-container">
                      <InputWraper
                        label={"Table ID"}
                        inputProps={getInputProps("tableId")}
                        component={InputText}
                      />
                    </div>
                    <div className="col-4 input-container">
                      <InputWraper
                        label={"Table Name"}
                        inputProps={getInputProps("tableName")}
                        component={InputText}
                      />
                    </div>
                    <div className="col-4 input-container">
                      <InputWraper
                        label={"Platform"}
                        inputProps={getInputProps("platform")}
                        component={InputText}
                      />
                    </div>
                    <div style={{ textAlign: "center" }} className="col-12">
                      <Button label="Create" buttonType="success" onClick={handleSubmit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="game-create-page__cover">
                <img
                  className="cover-image"
                  src="/assets/images/create-game-cover-image.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
});
