import React, { Fragment, memo, useState } from "react";

import { TableScroll, Button, onError, ObjectUtils, Table, CreateAlert, NumberUtils } from "../../../modules";
import { translate, getLocaleKey } from "../../../languages";
import { TableFilterRangeTimeInput, TableFilterInputText } from "../../../components/table-filter-inputs";
import { AdminService } from "../../../services/admin";
import { withTransactionWraper } from "../wraper";
import { useSelector } from "../../../store";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import _, { capitalize } from "lodash";
import moment from "moment";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
import CopyToClipboard from "react-copy-to-clipboard";
import { Icon } from "../../../components";
import { PopupWraper } from "../../../components/popup";
import { CompleteWithdraw } from "../complete-withdraw";
import XLSX from "xlsx";
import { getEnv } from "../../../configs";
import { ENetWork } from "../transaction/TransactionList";

export const WithdrawProgress = withTransactionWraper(
  memo(() => {
    const StatusFilterOptions = [
      { label: "JUST CREATED", value: "JUST_CREATED" },
      { label: "SUCCESS", value: "SUCCESS" },
      { label: "REJECTED", value: "REJECTED" },
      { label: "PROCESSING", value: "PROCESSING" },
    ];

    enum EActionType {
      APPROVE = "approve",
      REJECT = "reject",
    }

    const coinsState = useSelector((state) => state.coins);
    const coinsOptions = ObjectUtils.getIn(coinsState, "data", [], (arr) => arr.map((item: any) => ({ label: item.name, value: item.coinId })));
    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    const [actionType, setActionType] = useState<EActionType>();
    const [item, setItem] = useState<any>();
    const [fetchData, setFetchData] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>(false);
    const [showPopupComplete, setShowPopupComplete] = useState<any>(null);
    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
    const [params, setParams] = useState<any>();

    let handleOnClickApproveButton = (item: any, fetchData: any) => {
      setItem(item);
      setFetchData(fetchData);
      setActionType(EActionType.APPROVE);
      setIsShowPopup(true);
      setForceUpdateTable((state) => !state);
    };

    let handleOnClickRejectButton = (item: any, fetchData: any) => {
      setItem(item);
      setFetchData(fetchData);
      setActionType(EActionType.REJECT);
      setIsShowPopup(true);
      setForceUpdateTable((state) => !state);
    };

    let handleOnClickConfirm = (actionType: any, item: any, fetchData: any) => {
      setIsLoading(true);
      if (actionType === EActionType.APPROVE) {
        AdminService.withdrawApprove(item?.transactionRequestId)
          .then((res) => {
            fetchData();
            CreateAlert({ message: "Approved successfully", type: "success" });
          })
          .catch(onError)
          .finally(() => {
            setIsLoading(false);
            setIsShowPopup(false);
          });
      }

      if (actionType === EActionType.REJECT) {
        AdminService.withdrawReject(item?.transactionRequestId)
          .then((res) => {
            fetchData();
            CreateAlert({ message: "Rejected", type: "success" });
          })
          .catch(onError)
          .finally(() => {
            setIsLoading(false);
            setIsShowPopup(false);
          });
      }
    };

    let structure = [
      {
        name: "ADDRESS",
        key: "address",
        render: (item:any) => (
          <CopyToClipboard
            text={item?.address}
            onCopy={() =>
              CreateAlert({
                type: "success",
                message: "Copy Success",
              })
            }
          >
            <span className="copy">
              <Icon.Copy />
              {`${item?.address}` || "---------"}
            </span>
          </CopyToClipboard>
        ),
      },
      {
        name: "COIN",
        key: "coinId",
        render: (item:any) => {
          const coin = coinsOptions.find((v: any) => v.value === item.coinId);
          return `${ObjectUtils.getIn(coin, "label", "--")} (${item.network})`;
        },
      },
      {
        name: "TIME",
        key: "created",
        render: (item:any) => {
          const date = new Date(item.created);
          return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
        },
      },
      {
        name: "EMAIL",
        key: "email",
        className: "email",
      },
      {
        name: "AMOUNT",
        key: "value",
        render: (item:any) => (+item.value).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 }),
      },
      {
        name: "TRANSFER AMOUNT",
        key: "valueWithoutFee",
        render: (item:any) => (+item.valueWithoutFee).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 }),
      },
      {
        name: "STATUS",
        key: "status",
        render: (item: any) => (
          <span className={`status status--${item?.status?.toLowerCase()}`}>{_.startCase(_.toUpper(translate(item?.status)))}</span>
        ),
      },
      {
        name: "ACTIONS",
        render: (item:any, fetchData:any) => {
          return (
            <div className="d-flex flex-column">
              <Button
                type="button"
                buttonType="success-outline"
                label="Approve"
                onClick={() => handleOnClickApproveButton(item, () => fetchData)}
                disabled={item?.status !== "JUST_CREATED"}
              />

              <Button
                className="mt10 mb10"
                type="button"
                buttonType="danger-outline"
                label="Reject"
                onClick={() => handleOnClickRejectButton(item, () => fetchData)}
                disabled={item?.status !== "JUST_CREATED"}
              />

              <Button
                type="button"
                buttonType="info-outline"
                label="Complete"
                onClick={() => setShowPopupComplete(item.transactionRequestId)}
                disabled={item?.status !== "JUST_CREATED"}
              />
            </div>
          );
        },
      },
    ]

    const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
            try {
                const response = await AdminService.getListWithdrawProgress({
                  page: 1,
                  numberOfItemsPerPage: 10000,
                  ...params,
                });

                const data = response.data;

                const fileHead = structure.map((v) => v.name);
                const dataExport = [
                    fileHead,
                    ...data.map((item: any) =>
                        structure.map((column, index) => {
                            if (!column.key) return "";
                            if (column.key==='coinId') {
                              const coin = coinsOptions.find((v: any) => v.value === item.coinId);
                              return `${ObjectUtils.getIn(coin, "label", "--")} (${item.network})`;
                            };
                            if (column.key==='created') return moment(item[column.key]).format("L HH:mm:ss");
                            if (column.key==='value') {
                              return (+item.value).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 })
                            };
                            if (column.key==='valueWithoutFee') {
                              return (+item.valueWithoutFee).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 })
                            };
                            return item[column.key];
                        })
                    ),
                ];

                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

                const now = new Date();
                XLSX.writeFile(
                    wb,
                    `Withdraw Progress ${now
                        .toLocaleDateString()
                        .replace(/\//g, "-")} ${now
                        .toLocaleTimeString()
                        .replace(/:/g, "-")}.xlsx`,
                    { type: "binary" }
                );

                resolve(
                    CreateAlert({
                        type: "success",
                        message: "Export data success.",
                    })
                );
            } catch (error:any) {
                resolve(
                    CreateAlert({ type: "danger", message: error.message })
                );
            }
        });
    };

    return (
      <div className="WithdrawProgress">
        <Button
            className="mb20"
            label="Export to Excel"
            buttonType="success"
            onClick={handleExportExcel}
        />
        <Table
          hasOrderColumn
          itemPerPages={10}
          forceUpdateTable={forceUpdateTable}
          filters={[
            {
              name: "Time",
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              defaultValue: {
                fromDate: moment().subtract(3, "months").startOf("day").format("YYYY/MM/DD HH:mm:ss"),
                toDate: moment().endOf("day").format("YYYY/MM/DD HH:mm:ss"),
              },
            },
            {
              name: "Email",
              key: "email",
              input: TableFilterInputText,
            },
            {
              name: "Status",
              key: "status",
              input: (e) => <TableFilterInputSelect isClearable={true} {...e} options={StatusFilterOptions} isSearchable={false} />,
              defaultValue: "JUST_CREATED",
            },
          ]}
          structure={structure}
          fetchData={async (state) => {
            let params = { ...state };
            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

            setParams(params);

            return AdminService.getListWithdrawProgress({
              page: params.pageNumber,
              numberOfItemsPerPage: params.limit,
              ...params,
            });
          }}
        />
        {isShowPopup && (
          <GeneralPopupCompnt
            onClose={() => {
              setIsShowPopup(false);
            }}
            onClickConfirm={() => handleOnClickConfirm(actionType, item, fetchData)}
            textButton="OK"
            titlePopup={"Notification!"}
            messagePopup={[
              `Are you sure to `,
              [<span style={{ color: actionType === EActionType.APPROVE ? "#56ca00" : "#ff4c51" }}>{actionType}</span>],
              ` the withdrawal of `,
              [<span style={{ color: "#4680FF" }}>{item?.value} USDT</span>],
              ` by `,
              [<span style={{ color: "#4680FF" }}>{item?.email}</span>],
              `?`,
            ]}
            isLoading={isLoading}
          />
        )}
        {showPopupComplete && (
          <PopupWraper center title="COMPLETE WITHDRAW" onClose={() => setShowPopupComplete(null)}>
            <CompleteWithdraw
              id={showPopupComplete}
              onFinishEdit={() => {
                setShowPopupComplete(null);
                setForceUpdateTable((state) => !state);
              }}
            />
          </PopupWraper>
        )}
      </div>
    );
  })
);
