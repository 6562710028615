import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { InputSelect, InputText } from "../../../../components";
import { translate } from "../../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import { AdminService } from "../../../../services/admin";
import "./index.scss";

export const EditUser: FC<{ data: any; onFinishEdit: () => void }> = (props) => {
  const { email, firstName, userRole, username } = props.data;
  const [listRole, setListRole] = useState<Array<any>>([]);
  const [role, setRole] = useState<string>(userRole || "");

  useEffect(() => {
    AdminService.getListRole().then((res) =>
      setListRole(() => {
        return res.map((item: any) => {
          return { label: item === "SUPER_ADMIN" ? "SUPER ADMIN" : item, value: item };
        });
      })
    );
  }, []);

  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "email",
        defaultValue: email,
        validate: Yup.string().required(translate("must-be-provided")),
        isDisabled: true,
      },
      {
        name: "firstName",
        defaultValue: firstName,
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "username",
        defaultValue: username || "",
        // validate: Yup.string().required(translate("must-be-provided")),
        isDisabled: true,
      },
      // {
      //     name: "lastName",
      //     defaultValue: lastName,
      //     validate: Yup.string().required(translate("must-be-provided")),
      // },
      // {
      //     name: "phoneNumber",
      //     defaultValue: phoneNumber || "",
      //     validate: Yup.string().required(translate("must-be-provided")),
      // },
    ],
    // onSubmit: async (values) => {
    //     // console.log(values);
    //     return AdminService.updateProfile({ ...values, phoneCountryId: 1 })
    //         .then(() => {
    //             CreateAlert({
    //                 message: "Please check email to verify your account.",
    //                 type: "success",
    //             });
    //             props.onFinishEdit();
    //         })
    //         .catch((error) => {
    //             CreateAlert({
    //                 message: error.message,
    //                 type: "danger",
    //             });
    //         });
    // },
    onSubmit: async (values) => {
      const { userId, countryId: phoneCountryId } = props.data;
      if (role && role !== "")
        return AdminService.updateProfile({
          ...values,
          userRole: role,
          userId,
          phoneCountryId,
        })
          .then(() => {
            CreateAlert({
              message: "Update success.",
              type: "success",
            });
            props.onFinishEdit();
          })
          .catch((error) => {
            CreateAlert({
              message: translate(error.message),
              type: "danger",
            });
          });
      else
        CreateAlert({
          message: "Please choose a role!",
          type: "warning",
        });
    },
  });
  return (
    <form className="Edit" onSubmit={handleSubmit}>
      <InputWraper label={translate("email-address")} inputProps={getInputProps("email")} component={InputText} />
      <div className="group">
        <InputWraper label={"Display name"} inputProps={getInputProps("firstName")} component={InputText} className="mr5" />
        {/* <InputWraper
                    label={translate("last-name")}
                    inputProps={getInputProps("lastName")}
                    component={InputText}
                    className="ml5"
                /> */}
      </div>

      <InputWraper label={"Username"} inputProps={getInputProps("username")} component={InputText} className="mr5" />

      {/* <InputWraper
                label={translate("phone-number")}
                inputProps={getInputProps("phoneNumber")}
                component={InputText}
            /> */}
      <div className="item">
        <div className="label">Role</div>
        <InputSelect options={listRole} onChange={(e) => setRole(e)} onTouched={() => false} value={role} name="" />
      </div>

      <div>
        <Button
          // className="mb20"
          label="Update"
          // onClick={props.onClick}
          isMiddle
          buttonType="success"
          className="btnBan"
          type="submit"
        />
      </div>
    </form>
  );
};
