import React, { FC, useEffect, useState } from "react";
import { InputToggleSwitch } from "../../../../components/toggle-switch";
import { CreateAlert } from "../../../../modules";
import { AdminService } from "../../../../services/admin";
import "./index.scss";

export const Ban: FC<{ userId: number }> = (props) => {
  const [data, setData] = useState<{
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string | null;
    isFrozen: boolean;
    isFrozenTransfer: boolean;
    isFrozenWithdraw: boolean;
    isTwoFa: boolean;
  }>(null as any);
  const getIfo = async () => {
    try {
      const res = await AdminService.getUserDetail(props.userId);
      const { result, success } = res;
      if (success) {
        setData(result[0]);
      }
    } catch (error) {
      CreateAlert({
        type: "danger",
        // @ts-ignore
        message: error?.message || "Can't load data",
      });
    }
  };
  const showAlertError = (mess: string): void => {
    CreateAlert({
      type: "danger",
      message: mess,
    });
  };
  const showAlertSuccess = (mess: string): void => {
    CreateAlert({
      type: "success",
      message: mess,
    });
  };

  useEffect(() => {
    if (!props.userId) return;
    getIfo();
    // eslint-disable-next-line
  }, [props.userId]);
  return (
    <div className="Info">
      {data ? (
        <>
          <div className="group">
            <label>Email: </label> <p>{data.email}</p>
          </div>
          <div className="group">
            <label>Display Name: </label> <p>{data.firstName}</p>
          </div>
          {/* <div className="group">
            <label>LastName: </label> <p>{data.lastName}</p>
          </div> */}
          {/* <div className="group">
            <label>PhoneNumber</label> <p>{data.phoneNumber || "---"}</p>
          </div> */}

          {/* <div className="group">
            <label>Tranfer status</label>

            <InputToggleSwitch
              onChange={async (value) => {
                try {
                  if (data.isFrozenTransfer) {
                    const res = await AdminService.unfreezeUsersTransfer([
                      props.userId,
                    ]);
                    const { success } = res;
                    if (success) {
                      setData({
                        ...data,
                        isFrozenTransfer: false,
                      });
                      showAlertSuccess("Unblock tranfer success!");
                    }
                  } else {
                    const res = await AdminService.freezeUsersTransfer([
                      props.userId,
                    ]);
                    const { success } = res;
                    if (success) {
                      setData({
                        ...data,
                        isFrozenTransfer: true,
                      });
                      showAlertSuccess("Block tranfer success!");
                    }
                  }
                } catch (error) {
                  showAlertError(error?.message || "Try again!");
                }
              }}
              value={!data.isFrozenTransfer}
              onTouched={() => false}
              label=""
              name="tranfer"
            />
          </div> */}

          <div className="group">
            <label>Withdraw status</label>

            <InputToggleSwitch
              onChange={async (value) => {
                try {
                  if (data.isFrozenWithdraw) {
                    const res = await AdminService.unfreezeUsersWithdraw([props.userId]);
                    const { success } = res;
                    if (success) {
                      setData({
                        ...data,
                        isFrozenWithdraw: false,
                      });
                      showAlertSuccess("Unblock withdraw success!");
                    }
                  } else {
                    const res = await AdminService.freezeUsersWithdraw([props.userId]);
                    const { success } = res;
                    if (success) {
                      setData({
                        ...data,
                        isFrozenWithdraw: true,
                      });
                    }
                    showAlertSuccess("Block withdraw success!");
                  }
                } catch (error) {
                  // @ts-ignore
                  showAlertError(error?.message || "Try again!");
                }
              }}
              value={!data.isFrozenWithdraw}
              onTouched={() => false}
              label=""
              name="withdraw"
            />
          </div>

          <div className="group">
            <label>Login status</label>

            <InputToggleSwitch
              onChange={async (value) => {
                try {
                  if (data.isFrozen) {
                    const res = await AdminService.unfreezeUsers([props.userId]);
                    const { success } = res;
                    if (success) {
                      setData({
                        ...data,
                        isFrozen: false,
                      });
                      showAlertSuccess("Unblock login success!");
                    }
                  } else {
                    const res = await AdminService.freezeUsers([props.userId]);
                    const { success } = res;
                    if (success) {
                      setData({
                        ...data,
                        isFrozen: true,
                      });
                      showAlertSuccess("Block login success!");
                    }
                  }
                } catch (error) {
                  // @ts-ignore
                  showAlertError(error?.message || "Try again!");
                }
              }}
              value={!data.isFrozen}
              onTouched={() => false}
              label=""
              name="login"
            />
          </div>

          <div className="group">
            <label>Two-Fa status</label>

            <InputToggleSwitch
              onChange={async (value) => {
                try {
                  if (data.isTwoFa) {
                    const res = await AdminService.disableUsersTwoFa([props.userId]);
                    const { success } = res;
                    if (success) {
                      setData({
                        ...data,
                        isTwoFa: false,
                      });
                      showAlertSuccess("Disable two-fa success!");
                    }
                  } else {
                    const res = await AdminService.enableUsersTwoFa([props.userId]);
                    const { success } = res;
                    if (success) {
                      setData({
                        ...data,
                        isTwoFa: true,
                      });
                      showAlertSuccess("Enable two-fa success!");
                    }
                  }
                } catch (error) {
                  // @ts-ignore
                  showAlertError(error?.message || "Try again!");
                }
              }}
              value={data.isTwoFa}
              onTouched={() => false}
              label=""
              name="twoFa"
            />
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};
