import React, { FC, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";
import { InputSelect, InputText } from "../../../../components";
import { InputImageSingle } from "../../../../components/single-image";
import { InputToggleSwitch } from "../../../../components/toggle-switch";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import { AdminService } from "../../../../services/admin";
import { GameManagementLayout } from "../../components/game-management-layout";

export const GameEditPage: FC<any> = GameManagementLayout(() => {
  const { params }: any = useRouteMatch();
  const gameId = params?.id;
  const history = useHistory();
  const [dataGameDetail, setDataGameDetail] = useState<any>();
  const [categoryOptions, setCategoryOptions] = useState<any>();
  const [categoryValue, setCategoryValue] = useState<any>();
  const [supplierOptions, setSupplierOptions] = useState<any>();
  const [supplierValue, setSupplierValue] = useState<any>();
  const [isGameActive, setIsGameActive] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      await AdminService.getCategoryList().then((res: any) => {
        setCategoryOptions(
          res?.result?.map((item: any) => ({ label: item?.name, value: item?.categoryId }))
        );
      });
      await AdminService.getSupplierList().then((res: any) => {
        setSupplierOptions(
          res?.result?.map((item: any) => ({ label: item?.name, value: item?.supplierId }))
        );
      });
      await AdminService.getGameDetail(gameId).then((res: any) => {
        setDataGameDetail(res?.result);
        setCategoryValue(res?.result?.categoryId);
        setSupplierValue(res?.result?.supplierId);
        setIsGameActive(res?.result?.isActive);
      });
    })();
    //  eslint-disable-next-line
  }, []);

  const { getInputProps, handleSubmit } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "gameImage",
        validate: Yup.string().required("Must be provided"),
        defaultValue: { file: { name: dataGameDetail?.image }, src: dataGameDetail?.image },
      },
      // {
      //   name: "gameThumbnail",
      //   validate: Yup.string().required("Must be provided"),
      //   defaultValue: { file: { name: dataGameDetail?.thumnail }, src: dataGameDetail?.thumnail },
      // },
      {
        name: "tableId",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataGameDetail?.tableId,
        isDisabled: true,
      },
      {
        name: "tableName",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataGameDetail?.tableName,
      },
      {
        name: "platform",
        // validate: Yup.string().required("Must be provided"),
        defaultValue: dataGameDetail?.platform,
        isDisabled: true,
      },
    ],
    onSubmit: async (values: any) => {
      if (!categoryValue) return CreateAlert({ message: "Category required", type: "warning" });
      if (!supplierValue) return CreateAlert({ message: "Supplier required", type: "warning" });
      let payload = {
        categoryId: categoryValue,
        supplierId: supplierValue,
        image: values?.gameImage?.src,
        thumnail: values?.gameImage?.thumbnail,
        isActive: isGameActive,
        tableId: values?.tableId,
        tableName: values?.tableName,
        platform: values?.platform,
      };
      AdminService.EditGame(payload, gameId)
        .then((res: any) => {
          CreateAlert({ message: "Edit game successfully.", type: "success" });
        })
        .catch((err: any) => {
          CreateAlert({ message: err?.message, type: "danger" });
        })
        .finally(() => {
          history.push("/game-management-game");
        });
    },
  });

  return (
    <>
      {dataGameDetail && (
        <div className="game-edit-page container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="game-edit-page__content">
                <div className="title">Edit Game</div>
                <div className="form container">
                  <div className="row">
                    <div className="col-6 input-container">
                      <div className="label">Category</div>
                      <InputSelect
                        options={categoryOptions}
                        onChange={(e) => setCategoryValue(e)}
                        onTouched={() => false}
                        value={categoryValue}
                        name=""
                      />
                      {/* <InputWraper
                                        // className={"image"}
                                        label={"Category"}
                                        inputProps={getInputProps("category")}
                                        renderInput={(props) => <InputSelect
                                            options={categoryOptions}
                                            onChange={(e) => setCategoryValue(e)}
                                            onTouched={() => false}
                                            value={categoryValue}
                                            name=""
                                        />}
                                    /> */}
                    </div>
                    <div className="col-6 input-container">
                      <div className="label">Supplier</div>
                      <InputSelect
                        options={supplierOptions}
                        onChange={(e) => setSupplierValue(e)}
                        onTouched={() => false}
                        value={supplierValue}
                        name=""
                        isDisabled={true}
                      />
                    </div>
                    <div className="col-6 input-container">
                      <InputWraper
                        enableReinitialize
                        // className={"image"}
                        label={"Image"}
                        inputProps={getInputProps("gameImage")}
                        component={InputImageSingle}
                        // onChangeEvent={(value: any) => {}}
                      />
                    </div>
                    {/* <div className="col-6 input-container">
                      <InputWraper
                        enableReinitialize
                        // className={"image"}
                        label={"Thumbnail"}
                        inputProps={getInputProps("gameThumbnail")}
                        component={InputImageSingle}
                        // onChangeEvent={(value: any) => {}}
                      />
                    </div> */}
                    <div className="col-12 input-container">
                      <div className="label">Active</div>
                      <InputToggleSwitch
                        onChange={async (value) => setIsGameActive(value)}
                        value={isGameActive}
                        onTouched={() => false}
                        label=""
                        name=""
                      />
                    </div>
                    <div className="col-4 input-container">
                      <InputWraper
                        label={"Table ID"}
                        inputProps={getInputProps("tableId")}
                        component={InputText}
                      />
                    </div>
                    <div className="col-4 input-container">
                      <InputWraper
                        label={"Table Name"}
                        inputProps={getInputProps("tableName")}
                        component={InputText}
                      />
                    </div>
                    <div className="col-4 input-container">
                      <InputWraper
                        label={"Platform"}
                        inputProps={getInputProps("platform")}
                        component={InputText}
                      />
                    </div>
                    <div style={{ textAlign: "center" }} className="col-12">
                      <Button label="Save" buttonType="success" onClick={handleSubmit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="game-edit-page__cover">
                <img
                  className="cover-image"
                  src="/assets/images/edit-game-cover-image.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});
